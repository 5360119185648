<template>
  <base-layout-two
    page-title="Tasks"
    page-default-back-link="/home"
    content-class="background-white"
  >
    <section class="padding-x-16">
      <h1>Tasks</h1>
      <ion-button
        class="regular-button"
        expand="block"
        router-link="/tasks/create"
        >New Task</ion-button
      >
    </section>

    <ion-list class="padding-x-16">
      <task-list-item
        v-for="task in tasks"
        :key="task.id"
        :router-link="`/tasks/${task.id}`"
        :task="task"
        @check-task="checkTask"
      >
      </task-list-item>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import TaskListItem from "@/components/tasks/TaskListItem.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonList,
    IonButton,
    TaskListItem,
  },

  computed: {
    ...mapGetters(["globalProperty"]),
  },

  data() {
    return {
      tasks: [],
    };
  },

  ionViewWillEnter() {
    this.fetchTasks();
  },

  ionViewWillLeave(){
    this.tasks = []
  },

  methods: {   
    async fetchTasks() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/tasks`)
        .then((response) => {
          this.tasks = response.data.tasks;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async checkTask(task) {
      if (task.status) task.status = null;
      else task.status = "done";

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/tasks/${task.id}`,
        data: task,
      };

      await this.axios(config)
        .then(() => {
          // this.fetchTasks();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>